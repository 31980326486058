import React from 'react';
import { Col, Row } from 'antd';
import EventCard from '../eventCard/eventCard';
import { TBEvent } from '../../utils';
import styles from './homepageHero.module.css';

export interface HomepageHeroProps {
  title?: string;
  subtitle?: string;
  backgroundImage?: string;
  currentEvent?: Partial<TBEvent>;
}

const HomepageHero = ({ title, subtitle, backgroundImage, currentEvent }: HomepageHeroProps) => {
  return (
    <section className={styles.hero} style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="container">
        <Row className={styles.row}>
          <Col lg={8} className={styles.left}>
            <h1>{title}</h1>
            <h3>{subtitle}</h3>
          </Col>
          <Col lg={4} />
          <Col lg={12}>
            {currentEvent && (
              <div className={styles.cardWrapper}>
                <EventCard
                  image={currentEvent.banner?.url}
                  title={currentEvent.title}
                  description={'Op ons carrière evenement vind je bedrijven die bij jou passen.'}
                  linkUrl={'/event'}
                  linkLabel={'Lees meer'}
                  date={currentEvent.date}
                  location={currentEvent.location}
                />
              </div>
            )}
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default HomepageHero;
